import React from "react";
import queryString from "query-string";
import nProgress from "nprogress";
import Bowser from "bowser";
import PageWrapper from "./src/components/PageWrapper/PageWrapper";

export const onClientEntry = () => {
  const parsed: any = queryString.parse(window.location.search) || {};
  if (document.referrer) {
    parsed.referrer = document.referrer;
  }
  parsed.browser = Bowser.parse(window.navigator.userAgent);
  sessionStorage.setItem("utm_source", JSON.stringify(parsed));
  nProgress.configure({ showSpinner: false });
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname !== prevLocation?.pathname) {
    nProgress.done();
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }), 100);
  }
};

export const onRouteUpdateDelayed = () => {
  nProgress.start();
};

export const wrapPageElement = ({ element, props }) => {
  return <PageWrapper {...props}>
    {element}
    </PageWrapper>
}
